<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-datetime"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-datetime">
    <div v-if="rowData[source]">
      <div>{{firstLine}}</div>
      <div v-if="showTime">{{secondLine}}</div>
    </div>
    <div v-else>n/a</div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import moment from 'moment';

  export default {
    name: 'DateTimeField',
    mixins: [VuetableFieldMixin],
    computed: {
      source() {
        return this.rowField?.switch?.source;
      },
      showTime() {
        return this.rowField?.switch?.showTime ?? true;
      },
      firstLine() {
        return moment(this.rowData[this.source]).format('LL');
      },
      secondLine() {
        return moment(this.rowData[this.source]).format('LT');
      }
    }
  };
</script>
