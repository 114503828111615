import { ENTITY_FILTER_OPTIONS } from '@/components/auth/constants';

export default {
  name: "EntityFilterMixin",
  data: function () {
    return {
      entityFilterName: 'state',
      entityFilterValue: ENTITY_FILTER_OPTIONS.NONDELETED,
      entityDefaultFilter: { nonDeleted: true },
      entityFilterOptions: [
        { key: ENTITY_FILTER_OPTIONS.ALL, value: 'Any' },
        { key: ENTITY_FILTER_OPTIONS.NONDELETED, value: 'Active' },
        { key: ENTITY_FILTER_OPTIONS.DELETEDONLY, value: 'Deleted' },
      ],
    };
  },
  methods: {
    mapEntityFilterToQuery(value) {
      if (value === ENTITY_FILTER_OPTIONS.NONDELETED) {
        return {
          nonDeleted: true,
        };
      }

      if (value === ENTITY_FILTER_OPTIONS.DELETEDONLY) {
        return {
          deletedOnly: true,
        };
      }

      return {};
    },
  }
}
