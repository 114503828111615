export const BUNDLE_TYPES = {
  ALL: 'ALL',
  STAND_ALONE: 'STAND_ALONE',
  SUITE: 'SUITE',
};

export const AUTH_ALIAS = 'AUTH';
// TODO: check if localization is needed
export const AUTH_NAME = 'administrative setup';

export const ROLES = {
  CA: 'CUSTOMER_ADMIN',
  CA_READONLY: 'CUSTOMER_READONLY_ADMIN',
  USER: 'USER',
  NO_ROLE: 'NO_ROLE',
};

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
  DELETED: 'deleted',
};

export const ENTITY_FILTER_OPTIONS = {
  ALL: 'all',
  NONDELETED: 'nonDeleted',
  DELETEDONLY: 'deletedOnly',
};
